@import '../../__variables.scss';

#footer {
    background-color: $section-bg-blue;
    padding-top: 40px;
    padding-bottom: 40px;
    * {
        color: $text-white;
    }
  }

  @media (max-width: $breakpoint) {
    #footer {
        .copyright {
            text-align: center !important;
            margin-top: 30px;
        }
    }
}