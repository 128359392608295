$h1-font-size: 30px;
$h1-font-size-responsive: 25px;
$h1-text-transform: uppercase;

$h2-font-size: 25px;
$h2-font-size-responsive: 20px;
$h2-text-transform: lowercase;

$h3-font-size: 20px;
$h3-font-size-responsive: 15px;

$text-gray: #444344;
$text-white: #FFFFFF;
$text-highlight: #009ee3;
$text-lightgray:#828282;
$text-red:#ff0000;
$text-orange:#ff7626;

$button-color: #009ee3;

$section-bg-gray: #6d6d6d;
$section-bg-blue: #009ee3;
$section-bg-white: #FFFFFF;
$section-bg-invisible-black: #dedede;
$section-bg-invisible-black-light: #f5f5f5;

$breakpoint: 992px;

$bold: 500;

$border-color: $section-bg-gray;
$border-color-white: $section-bg-white;