@import '../../../__variables.scss';

#infoblock {
    margin-top: 10px;
    display: flex;
    .icon-wrapper {
        width: 30px;
        img {
            font-size: 25px;
        }
    }
    .content {
        width: 95%;
    }
}

@media (max-width: $breakpoint) {
    #infoblock {
        margin-top: 5px;
        margin-bottom: 25px;
    }
}