@import '../../../../__variables.scss';

.request-item-wrapper {
    padding: 5px 0px;
    display: flex;
    .indexLabel {
        width: 5%;
    }
    .property-list {
        width: 75%;
        .item {
            display: inline-block;
            width: 30%;
            margin-bottom: 3px;
            .key {
                font-weight: bold;
            }
        }
    }
    .controls {
        width: 20%;
        text-align: right;
        .control {
            display: inline-block;
            cursor: pointer;
            margin-left: 20px;
            &:hover {
                text-decoration: underline;
            }
            &.inEdit {
                color: $text-orange;
                cursor: default;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: $breakpoint) {
    .request-item-wrapper {
        .property-list {
            width: 70%;
            .item {
                width: 100%;
            }
        }
        .controls {
            width: 30%;
            .control {
                margin-left: 0px;
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }   
}