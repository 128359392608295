@import '../../../../../__variables.scss';

#checkoutform-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    z-index: 10000;
    .message {
        width: 100%;
        text-align: center;
        font-size: 3rem;
        color: $text-white;
        margin-top: 40vh;
    }
    .close {
        position: absolute;
        right: 1vw;
        top: 1vw;
        font-size: 3rem;
        color: $text-white;
        cursor: pointer;
        z-index: 10001;
        opacity: 1;
    }
}

@media (max-width: $breakpoint) {
    #checkoutform-overlay {
        .message {
            font-size: 30px;
            margin-top: 30vh;
        }
        .close {
            right: 3vw;
            top: 3vw;
            font-size: 30px;
        }
    }
}