@import '../../__variables.scss';

#header {
    background-color: $section-bg-white;
    padding: 20px 0px;
    border-bottom: thin solid $section-bg-blue;
    a, span, .faqmodal-trigger {
        color: $text-gray;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
        img {
            height: 30px;
        }
    }
    .main-menu, .secondary-menu {
        div {
            display: inline-block;
            line-height: 30px;
        }
    }
    .secondary-menu {
        text-align: right;
        div {
            margin-left: 50px;
        }
    }
    &.iframe-view {
        .main-menu {
            display: none;
        }
        position: fixed;
        width: 100%;
    }
}

@media (max-width: $breakpoint) {
    #header {
        padding: 5px 0px;
        position: fixed;
        width: 100%;
        z-index: 1000;
    }
}