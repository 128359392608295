@import '../../../__variables.scss';

#cart {
    margin-top: 25px;
    .add-to-cart-wrapper{
        margin-bottom: 25px;
        .add-to-cart.disabled {
            border-color: $section-bg-invisible-black;
            color: $section-bg-invisible-black;
            cursor: default;
        }
    } 
    .summary {
        .headline {
            border-top: thin solid $text-lightgray;
            color: $text-lightgray;
            font-size: $h2-font-size;
            padding-top: 10px;
        }
        .no-wires-yet {
            color: $text-lightgray;
        }
        .request-item-wrapper {
            border-bottom: thin solid $text-lightgray;
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .add-to-cart, .complete-request {
        cursor: pointer;
        color: $text-highlight;
        padding: 0px 15px;
        text-align: center;
        font-size: $h3-font-size;
        border: medium solid $section-bg-blue;
        display: inline-block;
    }
    .complete-request-wrapper {
        text-align: right;
    }
}