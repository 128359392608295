@import '../../../_variables';

#wire-usage {
    padding: 0px;
    .selector-wrapper {
        .selector {
            background-color: $section-bg-blue;
            color: $text-white;
            min-width: 250px;
            text-align: center;
            font-size: $h3-font-size;
            border: medium solid $section-bg-blue;
            cursor: pointer;
            text-transform: uppercase;
            // &.selected {
            //     background-color: $section-bg-white;
            //     color: $text-highlight;
            // }
        }
    }
}