@import '../../../__variables.scss';

#sleeve {
    .input-element-group-wrapper {
        .input-element-group {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            .input-element {
                .headline {
                    margin-bottom: 0px;
                }
                input {
                    border: thin solid $border-color;
                    outline: none;
                }
            }
            &:last-child {
                .input-element {
                    .headline {
                        margin-top: 25px;
                    }
                }
            }

        }
    }

}