@import '../../__variables.scss';

#home {
    margin-bottom: 20px;
    .h1 {
        padding-top: 50px;
        margin: 0px;
    }
    .h2 {
        padding-bottom: 50px;
        margin: 0px;
        font-size: $h2-font-size-responsive;
        color: $text-lightgray;
    }

    .configurator-accordion {
        .card {
            border: none;
            border-radius: 0px;
            .card-header {
                border: none;
                background-color: $section-bg-invisible-black;
                cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                    .section-headline-main-part {
                        font-weight: bold;
                    }
            }
            .card-body {
                padding: 10px;
                background-color: $section-bg-invisible-black-light;
            }
        }
    }
}