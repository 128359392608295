@import "../../../../../../__variables.scss";

.submodel-item {
  color: white;
  width: calc(100% / 3);
  border-right: thin solid $border-color-white;
  cursor: pointer;
  &:last-child {
    border: none;
  }

  &.disabled {
    cursor: default;
    background-color: $section-bg-invisible-black;
    color: $text-white;
  }
}
