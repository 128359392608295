@import '../../../__variables.scss';

#knowncombination {
    .order-table {
        margin-left: auto;
        margin-right: auto;
        .head {
            font-weight: $bold;
        }
        .content-row {
            display: flex;
            .key, .value {
                display: block;
            }
            .key {
                width: 20%;
            }
            .value {
                width: 80%;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .input-wrapper {
        .input-element {
            input {
                border: none;
                outline: none;
                padding: 3px;
                width: 100%;
                border: thin solid $border-color;
            }
        }
      }
}

@media (max-width: $breakpoint) {
    #knowncombination {
        .order-table {
            .content-row {
                display: flex;
                .key, .value {
                    display: block;
                    width: 50%;
                }
            }
        }
    }
}