@import '../../../__variables.scss';

#wireselection {
    .wireselection-type-wrapper {
        display: flex;
        .wireselection-type {
            width: 33%;
            text-align: center;
            input {
                margin-right: 10px;
            }
        }
    }
}