@import '../../../__variables.scss';

#newcombination {
    .input-element-group-wrapper {
        display: flex;
        .input-element-group {
            .input-element {
                input {
                    border: none;
                    outline: none;
                    border: thin solid $border-color;
                }
            }
            &:last-child {
                border-right: none;
            }
        }
    }
}

@media (max-width: $breakpoint) {
#newcombination {
    .input-element-group-wrapper {
        display: flex;
        .input-element-group {
            width: 50%;
            .input-element {
                input {
                    width: 60%;
                    border: none;
                    outline: none;
                    border: thin solid $border-color;
                }
            }
            &:last-child {
                border-right: none;
            }
        }
    }
}
}