@import '../../__variables.scss';

#importer {
    .headline {
        font-size: $h1-font-size;
        margin: 25px 0px;
    }

    .progress-label {
        margin-top: 25px;
        text-align: center;
    }
    
    .upload-trigger {
        margin-top: 25px;
        padding: 5px 10px;
        border: none;
        background-color: $section-bg-blue;
        color: $text-white;
        display: inline-block;
        cursor: pointer;
    }

    .custom-file-label {
        outline: none;
        box-shadow: none;
        border: thin solid $border-color;
        cursor: pointer;
    }
}