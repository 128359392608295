@import "../../__variables.scss";

#FAQModal {
  .modal-content {
    border-radius: 0px;
    .faq-table {
      .headline {
        font-weight: $bold;
      }
      .section-headline {
        font-weight: $bold;
        text-decoration: underline;
        margin-top: 20px;
      }
      .question {
        border-right: thin solid $border-color;
      }
      .question,
      .answer {
        border-top: thin solid $border-color;
      }
    }
  }
}

@media (max-width: $breakpoint) {
  #FAQModal {
    .modal-content {
      border-radius: 0px;
      .faq-table {
        .headline {
          display: none;
        }
        .question,
        .answer {
          width: 100%;
          border: none;
        }

        .question {
            font-weight: bold;
        }

        .answer {
        //   border-bottom: thin solid $border-color;
        margin-bottom: 10px;
        }
      }
    }
  }
}
