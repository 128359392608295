@import './__variables';

* {
    color: $text-gray;
    padding: 0px;
    margin: 0px;
}

.h1 {
    font-size: $h1-font-size !important;
    text-transform: $h1-text-transform;
}

#main-container {
    min-height: calc(100vh - 72px);
    
    .button {
        border-radius: 0.25rem;
        background-color: $button-color;
        padding: 10px 15px;
        border: none;
        color: $text-white;
        &:active, &:focus {
            border: none;
            outline: none;
        }
    }
    &.iframe-view {
        padding-top: 72px;
    }
}

@media (max-width: $breakpoint) {
    .h1 {
        font-size: $h1-font-size-responsive !important;
    }
}