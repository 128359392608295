@import '../../../__variables.scss';

.confirmationButton-wrapper {
    text-align: left;
    margin-top: 15px;
    .confirmationButton {
        display: inline-block;
        background-color: $section-bg-blue;
        color: $text-white;
        padding: 5px 10px;
        cursor: pointer;
    }
}