@import '../../../__variables.scss';

#wirelength {
       .input-wrapper {    
        .input-element-group-wrapper {
            display: flex;
            .input-element-group {
                .input-element {
                    input {
                        border: thin solid $border-color;
                        outline: none;
                    }
                }
                &:last-child {
                    border-right: none;
                }
                flex-grow: 1;
            }
        }
       }
    }