@import '../../__variables.scss';

#imprint {
    .headline {
        font-size: $h1-font-size;
        margin-bottom: 15px;
        margin-top: 25px;
        font-weight: $bold;
    }
    .subheadline {
        font-size: $h2-font-size;
        margin-bottom: 15px;
        font-weight: $bold;
    }
    .list {
        margin-bottom: 15px;
        ul {
            list-style-type: disc;
            margin-left: 20px;
        }
    }
    .seperator-headline {
        font-weight: $bold;
        margin-bottom: 15px;
    }
    .text {
        margin-bottom: 15px;
    }
}