@import '../../../../../__variables.scss';

.wireselection-model {
    text-align: center;
    margin-bottom: 10px;
    .main {
        font-weight: $bold;
        color: white;
        background-color: $section-bg-blue;
        padding: 10px 0px;
        cursor: pointer;
        &.disabled, &.hasSubModels {
            cursor: default;
            background-color: $section-bg-invisible-black;
        }
        &.disabled {
            color: $text-white;
        }
        &.hasSubModels {
            color: $text-highlight;
        }
    }
    .sub-items {
        display: flex;
        justify-content: center;
        background-color: $section-bg-blue;
        border-top: thin solid $border-color-white;
    }
    .not-available {
        font-weight: bold;
    }
}