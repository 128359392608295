@import '../../../../__variables.scss';

.knowncombination-result {
    padding: 5px;
    border-bottom: thin solid $border-color;
    cursor: pointer;
    &:hover, &.selected {
        background-color: $section-bg-blue;
        color: $text-white;
    }
}