@import '../../../../__variables.scss';

#attachedImages {
    .trigger-text {
        // margin-bottom: 25px;
        cursor: pointer;
        display: inline-block;
        &:hover {
            text-decoration: underline;
        }
    }
    .image-container {
        margin-top: 25px;
        display: flex;
        .image {
            width: 50%;
            text-align: center;
            img {
                max-height: 300px;
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $breakpoint) {
    #attachedImages {
        .image-container {
            display: block;
            .image {
                width: 100%;
                margin-top: 30px;
                &:first-child {
                    margin-top: 0px;
                }
                &:last-child {
                    margin-bottom: 30px;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
}