@import '../../../../__variables.scss';

#CheckoutForm {
    .modal-content {
        border-radius: 0px;
        .submit-button {
            background-color: $section-bg-blue;
            color: $text-white;
            border-radius: 0px;
            border: none;
            float: right;
        }
    }
}

@media (max-width: $breakpoint) {
    #CheckoutForm {
        margin: 0px;
    }
}