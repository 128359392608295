@import '../../../../../_variables';

#SuggestionTable {
    margin: 25px 0px;
    .header {
        display: flex;
        .item {
            width: 25%;
            color: $text-lightgray;
            border-bottom: thin solid $text-highlight;
            text-align: center;
            text-transform: uppercase;
            &:first-item {
                background-color: transparent;
                border: none;
            }
            &.sumOverlapPair {
                font-weight: bold;
            }
        }
    }
}